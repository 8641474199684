
import {IExpansionPanelContent} from './../../ui-partial/expansion-panel/expansion-panel.component'
import { EditType } from '../item-component-edit.service';

export enum ChangeLogType {
  ITEM_CREATION = "item_creation",
  REAL_EDIT = "real_edit",
  SUGGESTION_EDIT = "suggestion_edit",
  TRACKING_CHANGES_ENTER = "tracking_changes_enter",
  TRACKING_CHANGES_LEAVE = "tracking_changes_leave",
  STAGE_TRANSITION = "stage_transition",
  STAGE_STATUS = "stage_status",
  STAGE_ASSIGNMENT = "stage_assignment",
  GRAPHIC_REQUEST_CREATION = "graphic_request_creation",
  GRAPHIC_REQUEST_DELETION = "graphic_request_deletion",
  GRAPHIC_REQUEST_STATUS = "graphic_request_status",
}

export enum ActionType {
  ITEM_CREATION = "Created the item",
  TRACKING_CHANGES_ENTER = "Entered tracking changes mode",
  TRACKING_CHANGES_LEAVE = "Exited tracking changes mode",
  REAL_EDIT_ACCEPT_SUGG = "Accepted suggested change",
  REAL_EDIT_DIRECT = "Modified question directly",
  SUGGESTION_EDIT_REJECT_SUGG = "Rejected suggested change",
  SUGGESTION_EDIT_DIRECT = "Modified suggestion",
  SIGNED_OFF = "Signed off",
  SIGNED_OFF_REMOVE = "Removed Signed Off",
  DONE_EDITING = "Indicated Done Editing",
  DONE_EDITING_REMOVE = "Removed Done Editing",
  EDIT_REVIEW = "Indicated Edit Review",
  EDIT_REVIEW_REMOVE = "Removed Edit Review",
  CHANGES_REQUIRED = "Indicated Changes Required",
  CHANGES_REQUIRED_REMOVE = "Removed Changes Required",
  CHANGE_ASSIGNMENT = "Changed assignment for",
  MOVE_TO = "Move to",
  GRAPHIC_REQUEST_CREATION = "Created graphic request",
  GRAPHIC_REQUEST_DELETION = "Deleted graphic request",
  APPROVE_HIGH_CONTRAST = "Indicated Approved in High Contrast Mode",
  APPROVE_HIGH_CONTRAST_REMOVE = "Removed Approved in High Contrast Mode",
  UNASSIGN = "Unassigned",
  ASSIGN_TO = "Assign to",
  STATUS_CHANGE = "Change status to"
}


export enum ContentType {
  SUGGESTION = "SUGGESTION",
  REAL = "REAL",
  TRACKING_CHANGES = "TRACKING CHANGES",
  WORKFLOW_STAGE = "WORKFLOW STAGE",
  GRAPHIC_REQUEST = "GRAPHIC REQUEST",
}

export enum LogFilters {
  filter_suggestion = "filter_suggestion",
  filter_real = "filter_real",
  filter_tracking_changes = "filter_tracking_changes",
  filter_workflow_stage = "filter_workflow_stage",
  filter_graphic_request = "filter_graphic_request",
}

export enum TextDiffColors {
  RED = '#cc0000',
  GREEN = '#71ce69',
  GREY = '#bab8b8'
}

export interface ILogEntryData{
  contentType: ContentType,
  contentAction: string,
  author: string,
  time:string,
  details: {
    configBefore?: any,
    configAfter?: any,
    assigneesBefore?:any,
    assigneesAfter?:any,
    stageOrder?:number,
    stageTitle?:string,
  }
  isExpandView?:boolean

}

export interface IRenderedDiff {
  style: any,
  title: string,
  subTitle: string,
  description?: string,
  content: IExpansionPanelContent[],
  editType: EditType,
  originalDiff: any,
  isExpanded: boolean,
}


export interface IFilterSettings {
  slug: ContentType,
  filter:string,
  checked:boolean
}