import { IContentElementTextLink } from "../element-render-text-link/model";
import { FontFamily, IContentElement } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementText extends IContentElement {
    caption: string;
    paragraphStyle: TextParagraphStyle;
    simpleList?: string[];
    advancedList: IContentElement[];
    paragraphList?: IContentElementTextParagraph[];
    paragraphNumberingPos?: NumberingPosition;
    paragraphNumberingMargin?: number;
    paragraphNumberingScale?: number;
    paragraphNumberingStart?: number;
    paragraphSpaceAfter?: number; // em
    link?: IContentElementTextLink;
    isAdvancedOptionsDisabled?: boolean;
    font?: FontFamily;
    alignment?: string;
    rotation?: number;
    paragraphWidth?: number; // used for paragraphs
    fontSize?: number;
    lineHeight?: number;
    isInvisible?: boolean;
    advancedInlineWidth?: number;
    colour?: string;
    isShowDynamicText?: boolean;
    fromEntryId?: number;
    annotation?: string;
    text?: IContentElementText;
}

export enum NumberingPosition {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    NONE = "NONE"
}

export enum TextParagraphStyle {
    HEADLINE = "headline",
    HEADLINE_SMALL = "headline_small",
    REGULAR = "regular",
    SMALL = "small",
    BULLET = "bullet",
    NUMBERED = "numbered",
    PARAGRAPHS = "paragraphs",
    ADVANCED_INLINE = "advanced-inline",
    LINK = "link",
    ANNOTATION = "annotation"
}

export interface IContentElementTextParagraph extends IContentElement {
    caption?: string;
    paddingRight?: number;
    paddingLeft?: number;
    paragraphSpaceAfter?: number;
    numberingOverride?: string;
}

export const textEditInfo: IElementTypeEditInfo = {
    editExcludeFields: ['advancedList', 'link', 'paragraphList'],
    editTextFields: ['caption'],
    editKeyFieldsToShow: ['caption', 'paragraphStyle'],
    nonHiddenChangeLogFields: [ 'caption', 'link', 'paragraphStyle', 'simpleList', 'advancedList', 'text', 'content']
}

export const textParagraphEditInfo: IElementTypeEditInfo = {
    editExcludeFields: [],
    editTextFields: ['caption'],
    editKeyFieldsToShow: ['caption'],
    nonHiddenChangeLogFields: ['caption'],
    isNoKeyFieldsOverride: true // empty paragraph shows up as a # which is not its config property
}