import {UserRoles} from "./../../api/models/roles"

export interface IWorkflowStage {
  order: number,
  slug: string,
  isFinalStage?: boolean,
  isEditStage?:boolean,
  isReviewStage?:boolean,
  isTrackingChangesStage?: boolean;
  // What kind of users can be assigned to this stage
  assigneeRoles: UserRoles[],
  // What kind of users can assign other users to this stage
  assignerRoles: UserRoles[],
  // What kind of users can change stage settings and exit this stage
  changesRoles: UserRoles[],
  // What kind of users can return to this step from later steps
  moveBackRoles?: UserRoles[],
}

// Who can bring old items that have no stage into any stage
export const initOldItemsRoles: UserRoles[] = [
  UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER
]


export const AUTH_WORKFLOW_STAGES: IWorkflowStage[]= [
  {
    order: 1,
    slug: "auth_workflow_step_creation",
    assigneeRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    assignerRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    changesRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    moveBackRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER]
  },
  {
    order: 2,
    slug: "auth_workflow_step_edit",
    isEditStage: true,
    isTrackingChangesStage: true,
    assigneeRoles: [UserRoles.TEST_ITEM_AUTHOR_REV],
    assignerRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER, UserRoles.TEST_ITEM_AUTHOR_REV],
    changesRoles: [UserRoles.TEST_ITEM_AUTHOR_REV],
    moveBackRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
  },
  {
    order: 3,
    slug: "auth_workflow_step_review",
    isReviewStage: true,
    isTrackingChangesStage: true,
    assigneeRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    assignerRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    changesRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    moveBackRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
  },
  {
    order: 4,
    slug: "auth_workflow_step_final_review",
    assigneeRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    assignerRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    changesRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    moveBackRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
  },
  {
    order: 5,
    slug: "auth_workflow_step_approved",
    isFinalStage: true,
    assigneeRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    assignerRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER],
    changesRoles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER]
  }
]

export interface IStageAssignee {
  uid: number;
  first_name: string;
  last_name: string;
  contact_email: string;
  actual_role?:UserRoles;
}

export interface IStageInfo {
  order: number;
  assignees: IStageAssignee[];
  isStageWorkCompleted?: boolean
}

export type IStageInfoByQuestion = {
  [key: string]: {
    en?: IStageInfo;
    fr?: IStageInfo;
  };
};

export type IStageAssignmentByQuestion = {
  [key: string]: {
    en?: IStageAssignee[];
    fr?: IStageAssignee[];
  };
}