<div 
  [class.is-transparent-text]="element.isInvisible"
  [ngStyle]="getTextStyle()"
  class="inline-text-block" 
>
  <div 
    *ngIf="isSimpleText()"
    class="inline-text-block" 
  >
    <div
      [class.is-heading]="isHeading()"
      [class.is-heading-small]="isHeadingSmall()"
      [class.is-body]="isBody()"
      [class.is-small]="isSmall()"
      [ngStyle]="getChildStyle()"
      class="inline-text-block"
      [class.is-selected-edit]="editSelection.isSelectedEdit(element, questionPubSub)" 
      [style.border]="editSelection.getBorder(element, questionPubSub)"
    >
      <render-highlightable
        [inputString]="parseTextFormat(element.caption)"
        [entryId]="element.entryId"
        [prop]="'caption'"
      >
        <markdown-inline [input]="parseTextFormat(element.caption)"></markdown-inline>
      </render-highlightable>
    </div>
  </div>
  
  <div *ngIf="isBulletList()">
    <ul>
      <li *ngFor="let contentElement of element.advancedList">
        <div [class.is-selected-edit]="editSelection.isSelectedEdit(contentElement, questionPubSub)" [style.border]="editSelection.getBorder(contentElement, questionPubSub)">
          <element-render 
            [contentElement]="contentElement" 
            [questionState]="questionState" 
            [isLocked]="isLocked"
            [questionPubSub]="questionPubSub"
          ></element-render>
        </div>
      </li>
    </ul>
  </div>
  
  <div *ngIf="isParagraphs()" [style.width.em]="getWidth()">
    <div  
      *ngFor="let paragraph of element.paragraphList; let paragraphIndex = index"
      class="paragraph-row"
      [class.is-num-pos-left]="(element.paragraphNumberingPos === 'LEFT')"
      [class.is-num-pos-none]="(element.paragraphNumberingPos === 'NONE')"
      [style.margin-bottom.em]="paragraph.paragraphSpaceAfter || element.paragraphSpaceAfter"
    >
      <div
        [style.padding-left.em]="paragraph.paddingLeft || 0"
        [style.padding-right.em]="paragraph.paddingRight || 0"
        [class.is-selected-edit]="editSelection.isSelectedEdit(paragraph, questionPubSub)"
        [style.border]="editSelection.getBorder(paragraph, questionPubSub)"
        class="inline-text-block"
      >
        <render-highlightable
          [inputString]="parseTextFormat(paragraph.caption)"
          [entryId]="paragraph.entryId"
          [prop]="'caption'"
        >
          <markdown-inline [input]="parseTextFormat(paragraph.caption)"></markdown-inline>
        </render-highlightable>
      </div>
      <div 
        class="para-numbering" 
        [class.is-skipped]="paragraph.isNumHidden" 
        [style.width.em]="element.paragraphNumberingMargin || 0"
        [style.font-size.em]="element.paragraphNumberingScale || 1"
        >
        <!-- [style.padding-top.em]="renderParaNumPadding()" -->
        {{renderParaNum(paragraphIndex, paragraph)}}
      </div>
    </div>
  </div>
  
  <div *ngIf="isNumberedList()">
    <ol>
      <li *ngFor="let contentElement of element.advancedList">
        <div>
          <element-render 
            [contentElement]="contentElement" 
            [questionState]="questionState" 
            [isLocked]="isLocked"
            [questionPubSub]="questionPubSub"
          ></element-render>
        </div>
      </li>
    </ol>
  </div>
  
  <div *ngIf="isLink()">
    <element-render-text-link *ngIf="this.element.link" [element]="this.element.link"></element-render-text-link>
  </div>
  
  <div *ngIf="isAnnotation()">
    <element-render-annotation [element]="element" [questionState]="questionState" [isLocked]="isLocked"></element-render-annotation>
  </div>

  <div *ngIf="isAdvancedInlineText()" class="advanced-inline-container inline-block" [style.width.em]="getWidth()" [ngStyle]="getChildStyle()">
    <div *ngFor="let contentElement of element.advancedList" class="inline-text-block" [class.is-selected-edit]="editSelection.isSelectedEdit(contentElement, questionPubSub)" [style.border]="editSelection.getBorder(contentElement, questionPubSub)">
        <div [ngSwitch]="contentElement.elementType" class="inline-text-block" >
        <div *ngSwitchCase="'text'"   class="inline-text-block"> <element-render-text                 [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" class="inline-text-block" ></element-render-text> </div>
        <div *ngSwitchCase="'select_text'" class="inline-block"> <element-render-select-text          [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" [isLocked]="isLocked" [changeCounter]="contentElement._changeCounter"></element-render-select-text> </div>
        <div *ngSwitchCase="'annotation'" class="inline-block">  <element-render-annotation           [questionPubSub]="questionPubSub" [element]="contentElement"></element-render-annotation> </div>
        <div *ngSwitchCase="'text_link'"   class="inline-block"> <element-render-text-link            [questionPubSub]="questionPubSub" [element]="contentElement" ></element-render-text-link> </div>
        <div *ngSwitchCase="'math'"   class="inline-block is-math">      <element-render-math         [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" [isInline]="true"  ></element-render-math></div>
        <div *ngSwitchCase="'image'"  class="inline-block">      <element-render-image                [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" ></element-render-image></div>
        <div *ngSwitchCase="'table'"  class="inline-block">      <element-render-table                [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" ></element-render-table></div>
        <div *ngSwitchCase="'video'"  class="inline-block">      <element-render-video                [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" ></element-render-video></div>
        <div *ngSwitchCase="'mcq'"    class="inline-block is-mcq">      <element-render-mcq           [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" [questionPubSub]="questionPubSub" [isLocked]="isLocked"></element-render-mcq></div>
        <div *ngSwitchCase="'input'"  class="inline-block">      <element-render-input                [questionPubSub]="questionPubSub" [element]="contentElement" [questionState]="questionState" [questionPubSub]="questionPubSub" [isLocked]="isLocked"></element-render-input></div>
        <div *ngSwitchCase="'bookmark_link'" [style.font-size]="getFontSizeLink(contentElement)" class="inline-block"> <element-render-bookmark-link [questionPubSub]="questionPubSub" [element]="contentElement" ></element-render-bookmark-link> </div>
      </div>
    </div>
  </div>

</div>