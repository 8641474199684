
import * as _ from 'lodash';
import { ElementTypeDefs } from '../../../ui-testrunner/models/ElementTypeDefs';
import { IElementTypeEditInfo } from './../../../ui-testrunner/models/index'

//this file was added to keep track of all the differences and whether they should be shown or not
//If a log is missing  diff that's important to end user add that diff here

type SharedDiffRestriction = {
  prop: string;
  isAnyPosition?: boolean;
}

// Removed diffs (that don't count for logging a new version) shared by all elements
export const GEN_REMOVED_DIFFS: SharedDiffRestriction[] = [
  {prop: '_changeCounter'},
  {prop: 'entryId'},
  {prop: 'id'},
  {prop: 'updated_on'},
  {prop: 'scoreMatrix', isAnyPosition: true}
];



/** Determine if log diff should be hidden behind "view all changes" */
export const isDiffInLogHidden = (diff, questionObject) => {
  const diffPath = diff.path;

  // Find the last element in the path
  const elementLocationPath = _.initial(diffPath);
  const targetElement = _.get(questionObject, elementLocationPath)
  if (!targetElement || !targetElement.elementType) return false;

  // Find specific props for this element that are NOT hidden in diff log
  const targetElementType = targetElement.elementType
  const targetEditingInfo: IElementTypeEditInfo = ElementTypeDefs[targetElementType?.toUpperCase()]?.editingInfo; 
  const nonHiddenList = targetEditingInfo?.nonHiddenChangeLogFields || [];

  //Check if the target diff is not hidden
  const targetProp = _.last(diffPath)
  if (nonHiddenList.includes(targetProp)) {
    return false
  }

  return true;
}

/** Determine whether to exclude the diff from history log entirely */
export const isDiffInLogRemoved = (diff) => {
  const diffPath = diff.path;

  if (diffPath[0] == "langLink"){
    if (diffPath.length > 1 && diffPath[1] !== "content") return true;
  } else if (diffPath[0] !== "content") {
    return true;
  }

  // Remove if diff has a general prop that can be anywhere in the path to count
  const anyPositionPropList = GEN_REMOVED_DIFFS.filter(diff => diff.isAnyPosition).map(diff => diff.prop)
  const hasAnipositionDiff = diffPath.some(prop => anyPositionPropList.includes(prop));
  if (hasAnipositionDiff) return true;

  // Remove if diff is on one of the other specified properties to remove
  const sharedPropList = GEN_REMOVED_DIFFS.map(diff => diff.prop)
  const targetProp = _.last(diffPath)
  if (sharedPropList.includes(targetProp)) return true;

  // Otherwise don't remove
  return false;
}
